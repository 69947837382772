import React, {useState, useEffect, useRef} from 'react';
import { withStyles, TextField, Card,
	CardContent, Grid, MenuItem, Button,
	Tabs, Tab, Paper, Dialog
} from '@material-ui/core';
import {
  BrowserView,
  MobileView,
	isBrowser,
} from "react-device-detect";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FilterIcon from '@material-ui/icons/FilterList';
import Search from '@material-ui/icons/Search';
import { KeyboardDatePicker } from '@material-ui/pickers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Pagination from "material-ui-flat-pagination";
import Carousel from '../Carousel';
import EventCard from '../EventCard';
import Footer from '../Footer';
import rightLabel from '../../assets/images/right_label.svg';
import leftLabel from '../../assets/images/left_label.svg';
import { Translate } from '@material-ui/icons';

const Home = (props) => {
	const[events, setEvents] = useState([]);
	const[futureEvents, setFutureEvents] = useState([]);
	const[eventSegmentations, setEventSegmentations] = useState([]);
	const[totalEvents, setTotalEvents] = useState(0);
	const[offset, setOffset] = useState(0);
	const[tabValue, setTabValue] = useState(0);
	//const[page, setPage] = useState(1);
	const[executionStatus, setExecutionStatus] = useState('upcoming');
	const [open, setOpen] = React.useState(false);
	const refKeywords = useRef()
	const[filterValues, setFilterValues] = useState({
		keywords: '',
		category_id: '',
		date: null,
	})

	useEffect(() => {
		loadFutureEvents()
		loadAccountSegmentations()
		//loadEventsInfo(1)
	}, []);

	let loadEventsInfo = (page) => {
		let keywords = refKeywords.current.value;
		let start_date = filterValues.date === null ? '' : new Date(filterValues.date).toDateString();
		let category_id = filterValues.category_id;
		let execution_status = executionStatus;
		// uniandes uuid 14686ebc-5e09-481f-8
		//fetch(`https://connect.eventtia.com/en/api/v2/14686ebc-5e09-481f-8/events/list?page_size=8&page=${page}&keywords=${keywords}&start_date=${start_date}&category_id=${category_id}&execution_status=${execution_status}`)
		fetch(`https://connect.eventtia.com/en/api/v2/14686ebc-5e09-481f-8/events/list?page_size=12&page=${page}&show_in_widget=true&keywords=${keywords}&start_date=${start_date}&category_id=${category_id}&execution_status=${execution_status}&status=active&search_custom_fields%5B542305%5D=si`)
		.then(results => results.json())
		.then((data) => {
			setEvents(data.events)
			setTotalEvents(data.total_events)
		})
	}

	useEffect(() => {
		if(filterValues.keywords === '' && filterValues.category_id === '' && filterValues.date === null){
			loadEventsInfo(1)
		}
	}, [filterValues]);

	useEffect(() => {
		loadEventsInfo(1)
	}, [executionStatus])

	let loadFutureEvents = () => {
		fetch(`https://connect.eventtia.com/en/api/v2/14686ebc-5e09-481f-8/events/list?page_size=5&show_in_widget=true&execution_status=upcoming&featured=true&status=active&search_custom_fields%5B542305%5D=si`)
		.then(results => results.json())
		.then((data) => {
			setFutureEvents(data.events)
		})
	}

	let loadAccountSegmentations = () => {
		// uniandes uuid 14686ebc-5e09-481f-8
		//fetch(`https://connect.eventtia.com/en/api/v2/3b126652-7966-4910-b/events/list?page_size=8&page=${page}&keywords=${keywords}&start_date=${start_date}`)
		//fetch(`http://192.168.1.3:3001/en/api/v2/14686ebc-5e09-481f-8/event_segmentations/list?`)
		fetch(`https://connect.eventtia.com/en/api/v2/14686ebc-5e09-481f-8/event_segmentations/list?`)
		.then(results => results.json())
		.then((data) => {
			setEventSegmentations(data.segmentations)
		})
	}
	let handlePageClick = (offset, page) => {
		setOffset(offset)
		//setPage(page)
		loadEventsInfo(page)
	}

	let accountSegmentations = () => {
		let seg = [{value: '', label: ''}]
		if(eventSegmentations.length > 0){
			eventSegmentations.forEach((item, key) => {
				seg.push({value: item.id, label: item.name})
			})
		}
		return seg
	}

	const handleChange = name => event => {
		if(name === 'date') {
			setFilterValues({ ...filterValues, [name]: event });
		}else{
			setFilterValues({ ...filterValues, [name]: event.target.value });
		}
	}

	const handleChangeTab = (event, newValue) => {
		setTabValue(newValue)
		switch(newValue) {
			case 0:
				return setExecutionStatus('upcoming');
			case 1:
				return setExecutionStatus('all');
			case 2:
				return setExecutionStatus('past');
			case 3:
				return setExecutionStatus('current');
		}
	}

	let searchEvents = () => {
		loadEventsInfo(1)
	}

	let searchMobile = () => {
		loadEventsInfo(1)
		setOpen(false)
	}

	let clearFilters = () => {
		setFilterValues({
			keywords: '',
			category_id: '',
			date: null
		});
	}


  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }


	let cardEventsListHeader = () => {
		return(
			<div className='header'>
				<div className='title'>
					<div style={{fontWeight: '700', fontSize: '25px', fontFamily: 'Lato, sans-serif', fontStyle: 'normal'}}>Próximos eventos</div>
					<BrowserView>
						<div className={props.classes.clearFilters} onClick={() => clearFilters()}>Remover filtros</div>
					</BrowserView>
					<MobileView>
						<FilterIcon style={{color: '#FFF000'}} onClick={handleClickOpen}/>
					</MobileView>
				</div>
				<div className='filters'>
					<TextField
						id="outlined-bare"
						margin="normal"
						variant="outlined"
						placeholder='Keywords'
						className={props.classes.textField}
						inputRef={refKeywords}
					/>
					{isBrowser ? (
						<KeyboardDatePicker
							margin="normal"
							id="mui-pickers-date"
							label=""
							className={props.classes.datePicker}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							onChange={handleChange('date')}
							value={filterValues.date}
							format="MM/dd/yyyy"
							placeholder="MM/DD/YYYY"
							invalidDateMessage="Formato fecha inválido"
						/>
					) : (
						null
					)}
					{isBrowser ? (
						<TextField
							id="outlined-select-currency"
							select
							label="Categoria"
							className={props.classes.select}
							SelectProps={{
								MenuProps: {
									className: props.classes.menu,
								},
							}}
							helperText=""
							margin="normal"
							variant="outlined"
							onChange={handleChange('category_id')}
							value={filterValues.category_id}
						>
							{accountSegmentations().map(option => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
					) : (
						null
					)}
					<Button variant="contained" color="secondary" className={props.classes.button} onClick={() => searchEvents()}>
						<BrowserView>BUSCAR</BrowserView>
						<MobileView><Search style={{cursor: 'pointer'}} /></MobileView>
					</Button>
					<MobileView>
						<div className={props.classes.clearFilters} onClick={() => clearFilters()}>Remover filtros</div>
					</MobileView>
				</div>
			</div>
		)
	}

	let mobileFilters = () => {
		return(
			<Dialog fullScreen open={open} onClose={handleClose} className={props.classes.mobileFiltersContainer}>
				<div className='header' style={{display: 'flex', justifyContent: 'space-between', color: 'white'}}>
					<p>Filtrar eventos</p>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleClose}
						aria-label="Close"
						style={{color: '#FFF000', padding: 0}}
					>
						<CloseIcon />
					</IconButton>
				</div>
				<div>
					<KeyboardDatePicker
						margin="normal"
						id="mui-pickers-date"
						label=""
						className={props.classes.datePicker}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
						onChange={handleChange('date')}
						value={filterValues.date}
						format="MM/dd/yyyy"
						placeholder="MM/DD/YYYY"
						invalidDateMessage="Formato fecha inválido"
					/>
					<TextField
						id="outlined-select-currency"
						select
						label="Categoria"
						className={props.classes.select}
						SelectProps={{
							MenuProps: {
								className: props.classes.menu,
							},
						}}
						helperText=""
						margin="normal"
						variant="outlined"
						onChange={handleChange('category_id')}
						value={filterValues.category_id}
					>
						{accountSegmentations().map(option => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
					<Button variant="contained" color="secondary" className={props.classes.button} style={{width: '100%', borderRadius: 'unset'}} onClick={() => searchMobile()}>
						APLICAR
					</Button>
				</div>
			</Dialog>
		)
	}

	return(
		<div className="App">
			<div className='body'>
				{futureEvents.length > 0 && (
					<Carousel
						events={futureEvents}
					/>
				)}
				<Card className={props.classes.eventsCard}>
					{ cardEventsListHeader() }
					<CardContent>
						<Paper style={{boxShadow: 'unset'}}>
							<Tabs
									value={tabValue}
									onChange={handleChangeTab}
									indicatorColor="white"
									textColor="primary"
									scrollButtons='auto'
									className={props.classes.tabs}
								>
									<Tab label="Eventos próximos" className={props.classes.tabStyles} />
									<Tab label="Todos" className={props.classes.tabStyles} />
									<Tab label="Eventos pasados" className={props.classes.tabStyles} />
									<Tab label="Eventos hoy" className={props.classes.tabStyles} />
							</Tabs>
						</Paper>
						<Grid container>
							{events.map((item, key) => (
								(item.id === 14762 || item.category_id == null) ? (null) : (
									<Grid item xs={12} sm={3} key={key}>
										<EventCard
											event={item}
										/>
									</Grid>
								)
							))}
						</Grid>
						<div className='pagination-section' style={{marginTop: '10px'}}>
							<Pagination
								limit={8}
								offset={offset}
								total={totalEvents}
								onClick={(event, offset, page) => handlePageClick(offset,page)}
								classes={{text: props.classes.paginationText, rootCurrent: props.classes.paginationCurrent}}
								nextPageLabel={<img src={rightLabel} alt='rig'></img>}
								previousPageLabel={<img src={leftLabel} alt='left'></img>}
							/>
						</div>
					</CardContent>
				</Card>
				{mobileFilters()}
			</div>
			<Footer />
		</div>
	)
}

const styleSheet = theme => ({
	// carousel: {
	// 	height: '100%',
	// 	zIndex: '3',
	// 	'& .image-gallery-image img': {
	// 		height: '100%'
	// 	}
	// },
	eventsCard: {
		marginLeft: '10%',
		marginRight: '10%',
		marginBottom: '5%',
		borderRadius: '20px 20px 0 0',
		position: 'relative',
		marginTop: '1%',
		boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.07), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.06)',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
			marginRight: '0',
			marginTop: '0',
			marginBottom: '5%',
			borderRadius: 'unset'
		},
		'& .header': {
			backgroundColor: '#211D31',
			color: 'white',
			padding: '5px 10px',
			'& .title': {
				display: 'flex',
				justifyContent: 'space-between',
				padding: '5px 30px 5px 20px',
				[theme.breakpoints.down('sm')]: {
					padding: '10px 20px 10px 12px'
				}
			},
			'& .filters': {
				///padding: '10px',
				display: 'flex',
				justifyContent: 'space-around',
				alignItems: 'center',
				'& .MuiFormControl-root': {
					marginRight: '10px',
					padding: '0 !important',
					[theme.breakpoints.down('sm')]: {
						marginRight: 0
					}
				},
				[theme.breakpoints.down('sm')]: {
					flexWrap: 'wrap',
					justifyContent: 'flex-start'
				}
			}
		}
	},
	eventsTimeFilter: {
		display: 'none',
		justifyContent: 'space-evenly',
		padding: '20px',
		fontFamily: 'Lato, sans-serif',
		fontWeight: '400',
		fontSize: '15px',
		fontStyle: 'normal',
		[theme.breakpoints.down('sm')]: {
			flexWrap: 'wrap'
		},
		'& button': {
			borderRadius: '30px',
			textTransform: 'none',
			borderColor: '#211D31',
			width: '20%',
			[theme.breakpoints.down('sm')]: {
				width: '50% !important',
				marginBottom: '5px'
			},
		}
	},
	textField: {
		width: '25%',
		color: 'black',
		fontSize: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '70%',
		},
		backgroundColor: 'white',
		borderRadius: '4px',
		height: '40px',
		'& div': {
			height: '40px'
		},
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
	},
	datePicker: {
		width: '25%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		backgroundColor: 'white',
		marginLeft: '3px',
		borderRadius: '4px',
		height :'40px',
		'& div': {
			height: '40px'
		},
		'& div .MuiInputBase-input':{
			height: '1.6875em',
			paddingLeft: '20px'
		}
	},
	select: {
		width: '25%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		backgroundColor: 'white',
		borderRadius: '4px',
		marginLeft: '3px',
		height: '40px',
		lineHeight: '40px',
		'& div': {
			height: '40px'
		},
		'& div div': {
			display: 'flex',
			alignItems: 'center'
		},
		'& .MuiInputLabel-shrink': {
			display: 'none'
		},
		'& label': {
			transform: "translate(14px, 15px) scale(1)"
		},
		'& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
				borderColor: 'black',
				'& label': {
					display: 'none'
				}
			},
			'& fieldset': {
				display: 'none'
			}
    },
	},
	button: {
		width: '12%',
		height: '40px',
		fontFamily: 'Lato, sans-serif',
		fontStyle: 'normal',
		fontWeight: '700',
		fontSize: '16px',
		marginTop: '17px',
		borderRadius: 'unset',
		[theme.breakpoints.down('sm')]: {
			width: '25%',

		},
		margin: '8px',
		backgroundColor: '#FFF000 !important',
		color: 'black'
	},
	tabStyles: {
		borderRadius: '30px',
		border: '1px solid #211D31',
		margin: '0 3%',
		textTransform: 'capitalize',
		[theme.breakpoints.down('sm')]: {
			margin: '0 3% 10px 0'
		}
	},
	tabs:{
		display: 'flex',
		justifyContent: 'space-evenly',
		width: '100% !important',
		'& .MuiTabs-flexContainer': {
			width: '100%',
			//margin: '15px 0',
			'& .MuiButtonBase-root': {
				minHeight: '30px',
			},
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center',
				flexWrap: 'wrap'
			}
		},
		'& .Mui-selected': {
			color: 'white',
			backgroundColor: '#211D31'
		}
	},
	paginationText: {
		color: 'black'
	},
	paginationCurrent: {
		backgroundColor: '#FFF000',
		borderRadius: '50%',
		width: '32px',
		height: '32px',
	},
	activeButton: {
		backgroundColor: '#211D31',
		color: 'white'
	},
	appBarModal: {
		position: 'relative',
		backgroundColor: '#211D31',
		padding: '20px'
	},
	mobileFiltersContainer: {
		'& .MuiDialog-container .MuiPaper-root': {
			backgroundColor: '#211D31',
			padding: '20px'
		}
	},
	clearFilters: {
		color: '#FFF000 !important',
		fontWeight: '700',
		fontSize: '15px',
		fontFamily: 'Lato, sans-serif',
		textDecoration: 'underline',
		cursor: 'pointer'
	}
})

export default withStyles(styleSheet)(Home);